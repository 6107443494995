import { createContext, useContext, useEffect, useState } from "react";
import AuthService from "../../services/auth/auth.service";
import Loader from "../../components/UI/Loader/Loader";
import { NotificationContext } from "../notification/notification.provider";
import { useNavigate } from "react-router-dom";
import { userRoles } from "../../const/users";

export const initialUser = {
  loading: true,
  isLogin: false,
  fullName: "",
  email: "",
  id: "",
  role: ""
};

export const AuthContext = createContext({ user: initialUser });

const AuthProvider = props => {
  const { children } = props;
  const [user, setUser] = useState(initialUser);
  const { handleOpenNotification } = useContext(NotificationContext);
  const navigate = useNavigate();

  const login = values => {
    return AuthService.login(values.email, values.password).then(res =>
      setUser({
        ...res,
        loading: false,
        isLogin: true
      })
    );
  };

  const logout = () => {
    AuthService.logout()
      .then(() => {
        setUser({
          ...initialUser,
          loading: false
        });
        navigate("/");
      })
      .catch(() => handleOpenNotification("Something go wrong", "error"));
  };

  const checkLogin = () => {
    return AuthService.refreshToken()
      .then(res =>
        setUser({
          ...res,
          loading: false,
          isLogin: true
        })
      )
      .catch(() =>
        setUser({
          ...initialUser,
          loading: false
        })
      );
  };

  const checkIsAdmin = () => user.role === userRoles.Admin;
  const checkIsManager = () => user.role === userRoles.Manager;
  const checkIsAdminOrManager = () =>
    user.role === userRoles.Admin || user.role === userRoles.Manager;
  const isUserFabio = () => user.email === "fabio@rockfundgroup.com";
  const isDev = () => user.email === "sergey.malysheff@gmail.com";

  useEffect(() => {
    checkLogin();
  }, [0]);

  const value = {
    isDev,
    isUserFabio,
    user,
    login,
    logout,
    setUser,
    checkIsAdmin,
    checkIsAdminOrManager,
    checkIsManager
  };

  if (user.loading) {
    return <Loader global />;
  }

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export default AuthProvider;
