export const contactNotification = {
  successCreate: "New contact is successfully created",
  successUpdate: "Contact details are updated",
  successDeleted: "Contact is successfully deleted"
};
export const importNotification = {
  successUpload: "Upload successful! Please reload application",
  uploadWithErrors:
    "Upload with errors or duplicates! Please reload application",
  successFileDeleted: "File is successfully deleted",
  unSuccessUpload: "Upload unsuccessful"
};
export const noteNotification = {
  successCreate: "New note is successfully created",
  successUpdate: "Note is successfully updated",
  successDeleted: "Note is successfully deleted"
};
export const profileNotification = {
  successUpdate: "Profile details were updated"
};
export const propertyNotification = {
  successCreate: "New property is successfully created",
  successUnlink: "Property successfully unlinked",
  errorUnlink: "Unlink unsuccessful. Property must have at least one contact",
  successUpdate: "Property is successfully updated",
  successDeleted: "Property is successfully deleted"
};
export const preSellerRequirementNotification = {
  successCreate: "New Pre-Seller Requirement is successfully created",
  successUpdate: "Pre-Seller Requirement is successfully updated",
  successDeleted: "Pre-Seller Requirement is successfully deleted"
};
export const sellerRequirementNotification = {
  successCreate: "New Seller Requirement is successfully created",
  successUpdate: "Seller Requirement is successfully updated",
  successDeleted: "Seller Requirement is successfully deleted"
};
export const buyerRequirementNotification = {
  successCreate: "New Buyer Requirement is successfully created",
  successUpdate: "Buyer Requirement is successfully updated",
  successDeleted: "Buyer Requirement is successfully deleted"
};
export const usersNotification = {
  successCreate: "New user is successfully created",
  successResend: "Invite is successfully resend",
  successUpdate: "User is successfully updated",
  successDeleted: "User is successfully deleted"
};
export const formNotification = {
  requiredFields: "Missing required fields!"
};
export const defaultError = "Something went wrong!";
export const defaultSuccess = "Done!";
export const defaultCopy = "Text copied!";
