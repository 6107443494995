import Snackbar from "@mui/material/Snackbar";
import * as React from "react";
import { createContext, useState } from "react";
import Notification from "../../components/UI/Notification/Notification";
import CopyNotification from "../../components/UI/CopyNotification/CopyNotification";
import {
  defaultCopy,
  defaultError,
  defaultSuccess
} from "../../const/notifications";

export const NotificationContext = createContext({});

const NotificationProvider = props => {
  const { children } = props;

  const [state, setState] = useState({
    open: false,
    message: defaultSuccess,
    severity: "success",
    horizontal: "center",
    copy: false
  });

  const openSuccessNotification = (message = defaultSuccess) =>
    setState({
      open: true,
      message,
      severity: "success",
      copy: false,
      horizontal: "center"
    });
  const openErrorNotification = (message = defaultError) =>
    setState({
      open: true,
      message,
      severity: "error",
      copy: false,
      horizontal: "center"
    });
  const openCopyNotification = (message = defaultCopy) =>
    setState({
      open: true,
      message,
      severity: "success",
      copy: true,
      horizontal: "right"
    });

  const handleClose = () =>
    setState(prevState => ({ ...prevState, open: false }));

  const { message, severity, open, copy, horizontal } = state;
  return (
    <NotificationContext.Provider
      value={{
        openSuccessNotification,
        openErrorNotification,
        openCopyNotification
      }}
    >
      {children}
      <Snackbar
        autoHideDuration={3000}
        anchorOrigin={{ vertical: "top", horizontal }}
        open={open}
        onClose={handleClose}
      >
        <div>
          {copy ? (
            <CopyNotification message={message} />
          ) : (
            <Notification
              message={message}
              severity={severity}
              onClose={handleClose}
            />
          )}
        </div>
      </Snackbar>
    </NotificationContext.Provider>
  );
};

export default NotificationProvider;
